<template>
    <v-dialog
      :value="dialog"
      @input="dialog = $event"
      max-width="300px"
      scrollable
    >   
      <v-card>
        <v-card-title>번역 국가</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 300px;">
          <v-radio-group
            v-model="dialogm1"
            column
          >
          <!-- TODO: fill out the contries (maybe using data?)-->
           <v-radio v-for="(country, index) in countries" :key="index" :label="`${country.label} (${country.value})`" :value="country.value"></v-radio>
           
          </v-radio-group>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            text
            @click="dialog = false"
          >
            취소
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="dialog = false"
          >
            다음
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  export default {
    data() {
      return {
        dialogm1: '',
        countries:
[
{label:"대한민국", value:"KR",},
{label:"미국", value:"US",},
{label:"일본", value:"JP",},
{label:"중국", value:"CN",},
{label:"영국", value:"GB",},
{label:"인도", value:"IN",},
{label:"프랑스", value:"FR",},
{label:"독일", value:"DE",},
{label:"브라질", value:"BR",},
{label:"이스라엘", value:"IL",},
{label:"스페인", value:"ES",},
]
      };
    },
    props: {
      value: {
        type: Boolean,
        required: true,
      },
    },
    computed: {
      dialog: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit('input', value);
        },
      },
    },
  };
  </script>