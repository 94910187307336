<!-- Main, 1-6 -->
<template>
  <v-card
    class="content-bg card mx-auto"
    :max-width="card.maxWidth"
    :min-width="250"
    flat
    tile
    router
    :to="video.url"
    height="100%"
  >
  <v-container class="pa-0">
    <v-row no-gutters>
      <v-spacer></v-spacer>
      <v-col class="py-0">
        <v-img :src="video.thumb" height="200px" width="150px" class="rounded" style="flex-grow: 0;"></v-img>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>    
    <v-row no-gutters>
      <v-col cols="2" v-if="card.type != 'noAvatar'">
        <v-list-item class="pl-0 pt-3" router :to="channel.url">
          <v-list-item-avatar color="grey darken-3">
            <v-img class="elevation-6" :src="channel.avatar"></v-img>
          </v-list-item-avatar>
        </v-list-item>
      </v-col>
      <v-col cols="8">
        <v-card-title class="pl-2 pt-3 pr-0 subtitle-1 font-weight-bold headerClass">
          {{ video.title }}
        </v-card-title>

        <v-card-subtitle class="pl-2 pb-0 pr-0">
          {{ channel.name }}
        </v-card-subtitle>
        <v-card-subtitle class="pl-2 pt-0 pr-0">
          {{ video.views }} <v-icon>mdi-circle-small</v-icon
          >{{ video.createdAt }}
        </v-card-subtitle>
      </v-col >
      <v-col cols ="2" class="align-self-center">
        <template>
        <div class="text-center align-self-center">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                fab
                text
                v-bind="attrs"
                v-on="on"
                @click.stop.prevent
              >
              <v-icon>
                mdi-dots-vertical
              </v-icon>
              </v-btn>
            </template>
              <v-list>
              <v-list-item router to="#">
                <v-list-item-icon>
                  <v-icon>mdi-playlist-play</v-icon>
                </v-list-item-icon>
                <v-list-item-title>현재 감상목록에 추가</v-list-item-title>
              </v-list-item>
              <v-list-item router to="#">
                <v-list-item-icon>
                  <v-icon>mdi-clock-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>나중에 볼 콘텐츠에 저장</v-list-item-title>
              </v-list-item>
              <v-list-item router to="#">
                <v-list-item-icon>
                  <v-icon>mdi-share-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>공유</v-list-item-title>
              </v-list-item>
              
              <v-divider></v-divider>
            
              <v-list-item router to="#">
                <v-list-item-icon>
                  <v-icon>mdi-cancel</v-icon>
                </v-list-item-icon>
                <v-list-item-title>관심 없음</v-list-item-title>
              </v-list-item>
              <v-list-item router to="#">
                <v-list-item-icon>
                  <v-icon>mdi-minus-circle-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>채널 추천 안함</v-list-item-title>
              </v-list-item>
              <v-list-item router to="#">
                <v-list-item-icon>
                  <v-icon>mdi-flag-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>신고</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        </template>
      </v-col>
    </v-row>
  </v-container>

  </v-card>
</template>

<script>
export default {
  props: {
    video: {
      type: Object,
      required: true
    },
    channel: {
      type: Object,
      required: true
    },
    card: Object
  }
}
</script>

<style scoped>
.headerClass{

    word-break: normal;
    overflow: hidden ;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
</style>
