<!-- 7-A-1 -->
<template>
    <div>
        <v-dialog
          :value="dialog"
          @input="dialog = $event"
          max-width="400px"
        >   
          <v-card>
            <!-- <v-card-title>공유</v-card-title>
            <v-divider></v-divider> -->
            <v-card-title class="mt-5">
                <div class="col-12">
                    <p class="text-center text-body-1 mb-0"> 게시물로 공유</p>
                </div>
                <!-- <v-spacer></v-spacer>  -->
            </v-card-title>
            <v-card-text>
                <div class="text-center">
                    <v-btn rounded color="gray darken-4 " class="mb-5">게시물 작성</v-btn>
                    <p class="text-caption">구독자 없음</p>
                </div>
          <v-divider></v-divider>
          <v-container>
            <div
               class="d-flex justify-space-around mt-3"
            >
              <!-- TODO: KAKAO SHARE -->
              <v-btn dark fab bottom color="yellow" small :href="`https://www.linkedin.com/shareArticle?mini=true&url=${pageUrl}`" target="_blank">
                <v-img
                :src="require('@/assets/KakaoTalk_logo.svg')"
                contain
                height="24px"
                width="24px"
                ></v-img>                  
                </v-btn>
                <v-btn dark fab  bottom color="blue" small :href="`https://www.facebook.com/sharer/sharer.php?u=${pageUrl}`" target="_blank">
                    <v-icon>mdi-facebook</v-icon>
                </v-btn>
                <v-btn dark fab bottom color="green" small :href="`https://wa.me/?text=Checkout%20this%20page.%20${pageUrl}`" target="_blank">
                    <v-icon>mdi-whatsapp</v-icon>
                </v-btn>
                <v-btn dark fab bottom color="tertiary" small :href="`mailto:?subject=Awesomeness!&amp;body=Checkout this page!<a href='${pageUrl}'>${pageUrl}</a>`" target="_blank">
                    <v-icon>mdi-email</v-icon>
                </v-btn>
            </div>

            </v-container>
            
      <v-container>
        <!-- {{$route.fullPath}} -->
        <div class="d-flex align-center">
        <v-text-field class="mr-2" readonly v-model="sharelink">
        </v-text-field>
        <v-tooltip bottom :open-on-hover="false" close-delay="200">
          <template #activator="{ on }">
              <v-btn @click="copyText" @blur="on.blur" v-on="on" retain-focus-on-click rounded>복사</v-btn>
          </template>
        <span>복사 완료</span>
        </v-tooltip>
        </div>
      </v-container>
            </v-card-text>
            <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            text
            @click="dialog = false"
          >
            취소
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="dialog = false"
          >
            다음
          </v-btn>
            </v-card-actions>
            
        </v-card>
    </v-dialog>
</div>
</template>
    
<script>
export default {
    data() {
      return {
        dialog2: false,
        sharelink: "http://www.grape.com/watch/12",
        pageUrl: "http://www.grape.com/watch/12",
      };
    },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },  
  methods: {
    copyText() {
      navigator.clipboard.writeText(this.sharelink);
    },
  }
};
</script>